module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SmartDose AI","short_name":"SmartDoseAI","description":"SmartDose AI Tiên phong trong công nghệ y học chính xác - Giải pháp hỗ trợ cá thể hóa liều dùng cho bệnh nhân Việt Nam và Châu Á.","lang":"vi","icon":"src/assets/img/favicon.png","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#f7f0eb","theme_color":"#2282C2","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e90e68144d0837b2f6f0db7342dc561c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
